<template>
	<div class="dashboard-chart dashboard-chart-bar-pie">
		<!-- Chart -->
		<div class="dashboard-chart-container">
			<span
			class="dashboard-chart-tooltip"
			:class="{ 'opacity-0': !showTooltip }"
			:style="{ left: tooltipPosition.x + 'px' }"
			>{{tooltipValue}}</span>

			<chartist
			ratio=""
			type="Bar"
			:data="chartData"
			:options="chartOptions"
			:event-handlers="chartEvents" />
		</div>

		<!-- Bottom legend -->
		<ul class="dashboard-chart-legend">
			<li
			v-for="serie in chartData.legend"
			:key="serie.name"
			:data-series-name="serie.name">
				{{serie.label}}
			</li>
		</ul>
	</div>
</template>

<script>
	import userFieldsValues from "@/constants/userFieldsValues"

	export default {
		name: 'BarPieChart',
		props: {
			data: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				chartOptions: {
					low: 0,
					stackBars: true,
					horizontalBars: true,
					seriesBarDistance: 0,
					height: '58px',
					chartPadding: {
						top: 0,
						right: 0,
						bottom: 0,
						left: 5
					},
					axisX: {
						offset: 0,
						showLabel: false,
						showGrid: false,
					},
					axisY: {
						offset: 0,
						showLabel: false,
						showGrid: false
					}
				},
				chartEvents: [{
					event: 'draw',
					fn: this.onChartDraw
				}],
				tooltipValue: 0,
				tooltipPosition: { x: 0, y: 0 },
				showTooltip: false
			}
		},
		computed: {
			chartData() {
				const jobs = userFieldsValues.job.reduce((dict, job) => {
					dict[job.value] = (job.labelShort || job.label)

					return dict
				}, {
					all: 'Total'
				})

				const dataKey = Object.keys(this.data)

				const formattedData = dataKey.reduce((formattedData, key) => {
					formattedData.legend.push({
						name: key,
						label: (jobs[key] || key)
					})
					
					if (key == 'all') {
						formattedData.series.push({
							name: key,
							data: [this.data[key], 0]
						})
						return formattedData
					}

					formattedData.series.push({
						name: key,
						data: [0, this.data[key]]
					})

					return formattedData
				}, {
					legend: [],
					series: []
				})

				return formattedData
			}
		},
		methods: {
			onChartDraw(data) {
				// Replace bar "line" by "rect" to be able to add rounded borders and margin
				if (data.type === 'bar') {
					const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect')

					// Copy/update attributes
					const width = Math.max((data.x2 - data.x1 - 2), 0)

					rect.setAttributeNS(null, 'rx', 5)
					rect.setAttributeNS(null, 'ry', 5)
					rect.setAttributeNS(null, 'x', data.x1)
					rect.setAttributeNS(null, 'y', data.y1 - 10)
					rect.setAttributeNS(null, 'width', width)
					rect.setAttributeNS(null, 'class', 'ct-bar')
					rect.setAttributeNS('http://gionkunz.github.com/chartist-js/ct', 'ct:value', data.value.x)

					// Replace element
					data.element.getNode().replaceWith(rect)

					// Add event listeners for the tooltip display logic
					if (data.series.name != 'all') {
						rect.addEventListener('mouseover', () => {
							this.onBarMouseOver(data.value, data.x1, width)
						})
						rect.addEventListener('mouseout', () => {
							this.onBarMouseOut()
						})
					} else if (data.value.x > 0) {
						// Add custom label for the 'all' serie
						const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
						foreignObject.setAttributeNS(null, 'x', data.x1)
						foreignObject.setAttributeNS(null, 'y', (data.y1 - 10))
						foreignObject.setAttributeNS(null, 'width', width)
						foreignObject.setAttributeNS(null, 'height', 20)

						rect.parentElement.append(foreignObject)

						const span = document.createElement('span')
						
						span.setAttribute('class', 'ct-label ct-horizontal ct-end')
						span.setAttribute('xmlns', 'http://www.w3.org/2000/xmlns')

						span.textContent = 'Total : ' + this.formatTime(data.value.x, true)

						foreignObject.append(span)
					}
				}
			},
			onBarMouseOver(serieData, x, width) {
				this.tooltipValue = this.formatTime(serieData.x)
				this.tooltipPosition = { x: (x + (width / 2)), y: 0 }
				this.showTooltip = true
			},
			onBarMouseOut() {
				this.showTooltip = false
			},
			formatTime(time, full) {
				const oneHour = (1 * 60 * 60 * 1000)
				let hours = (time / oneHour)
				let rHours = Math.floor(hours)
				let min = Math.round((hours - rHours) * 60)

				return rHours + 'h' + (min < 10 ? '0' + min : min) + (full ? 'min' : '')
			}
		}
	}
</script>

<style lang="scss">
	@namespace ct "http://gionkunz.github.com/chartist-js/ct";

	.dashboard-chart-bar-pie {
		.dashboard-chart-container {
			.ct-bar {
				stroke-width: 0;
				height: 20px;

				&:hover {
					@apply transition-stroke-width duration-500;
					stroke-width: 2;
				}
			}

			.ct-series[ct|series-name="all"] {
				.ct-bar {
					@apply fill-dashboard-gray-lighter stroke-dashboard-gray-lighter;
					stroke-width: 2;
				}

				.ct-label.ct-end {
					@apply block text-center;
					line-height: 20px;
				}
			}
		}

		.dashboard-chart-tooltip {
			@apply mb-2 w-auto px-3;
			transform: translateX(-50%);
		}

		.dashboard-chart-legend {
			@apply flex flex-wrap border-t-1 border-dashboard-blue-lighter mt-10 pt-6;

			li {
				@apply w-1/3 pr-6;
			}

			li[data-series-name="all"]:before {
				@apply text-dashboard-gray-lighter;
			}
		}
	}
</style>