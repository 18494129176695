<template>
    <div>
        <cooc-list
            v-if="!isLoading"
            listing="user" 
            :fields="fields" 
            :filters="filters" 
            searchText="Rechercher un utilisateur"
            :with-delete-button="false"
            modify-button-text="Éditer la fiche Utilisateur"
        >
            <template v-slot:buttons="{ items }">
                <div v-if="!isReadOnly" class="md:flex flex-row justify-end hidden user-buttons w-1/3">
                    <button
                        type="button"
                        class="hidden md:block twn-button whitespace-no-wrap overflow-hidden text-xs mr-4"
                        :disabled="managedCustomer && (managedCustomer.quota - filterUser(items).length) <= 0"
                        @click="addUser">
                        Ajouter un utilisateur
                    </button>
                    <button
                        type="button"
                        class="twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs"
                        :disabled="managedCustomer && (managedCustomer.quota - filterUser(items).length) <= 0"
                        @click="importUserList">
                        Importer des utilisateurs
                    </button>
                </div>
                <div class="w-full text-right mt-2" v-if="isUserManager">
                    <p class="font-principal">
                        <span class="font-principal-medium">{{ (managedCustomer.quota || 0) - filterUser(items).length }}</span> sur <span class="font-principal-medium">{{ (managedCustomer.quota || 0) }}</span> invitations restantes
                    </p>
                </div>
            </template>
            <template v-slot:fab>
                <vue-fab
                    class="md:hidden"
                    fabItemAnimate="alive"
                    fabAliveAnimateBezier="ease-in-out"
                    mainBtnColor="#fff"
                    size="big"
                >
                    <fab-item class="twn-fab import" @clickItem="importUserList" color="#888888" :idx="2" title="Importer des utilisateurs" icon="add" />
                    <fab-item class="twn-fab add" @clickItem="addUser" color="#DC9799" :idx="0.5" title="Ajouter un utilisateur" icon="add" />
                </vue-fab>
            </template>
        </cooc-list>

        <UserImportModal v-model="isImportModalOpen" />
    </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';

import userFieldsValues from "@/constants/userFieldsValues"

import { getUserProgressData, getUserState, getUserGroupState } from "@/utils/userUtils"

import List from '@/components/List'
import UserImportModal from '@/components/common/user/UserImportModal'

export default {
    name: 'UserList',
    data() {
        return {
            isLoading: true,
            isImportModalOpen: false,
            level1: null,
        }
    },
    props:{
        previousPage: {
            type: Number,
            required: false,
            default: null
        }
    },
    components: {
        'cooc-list':List,
        UserImportModal
    },
    computed: {
        ...mapState('Auth', [ 'userInfo' ]),
        isSuperAdmin() {
            return (this.userInfo && this.userInfo.role == 'superadmin')
        },
        isUserAdmin() {
            return (this.userInfo && (this.userInfo.role == 'superadmin' || this.userInfo.role == 'customer_manager'))
        },
        isUserManager() {
            return (this.userInfo && this.userInfo.role == 'customer_manager')
        },
        isReadOnly() {
            return !this.isSuperAdmin && !this.isUserAdmin
        },
        filters() {
            if (this.isSuperAdmin) {
                return [
                    {
                        key:'role',
                        label: 'Rôle'
                    }
                ]
            }
            
            return []
        },
        fields() {
            let fields = [
                {
                    key: 'last_name',
                    label: 'Nom',
                    sortable: true
                },
                {
                    key: 'first_name',
                    label: 'Prénom',
                    sortable: true,
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true,
                },
                {
                    key: 'role',
                    sortable: true,
                    formatter: (value) => {
                        switch (value){
                            case 'user':
                                return "Utilisateur"

                            case 'customer_manager':
                                return "Responsable client"

                            case 'superadmin':
                                return "SuperAdmin"
                        }
                    }
                },
                {
                    key: 'promotions',
                    db: `promotions: groups{
                        promotion: group {
                            identifier
                            name
                        }
                    }`,
                    label: 'Promotion(s)',
                    sortable: true,
                    formatter: (values) => {
                        if (!values || values.length <= 0)
                            return 'Aucun'

                        return values.map(value => [value.promotion.identifier, value.promotion.name].filter(str => str || false).join(' - ')).join(', ') || '~ Promotion supprimée ~'
                    }
                },
                {
                    key: 'disabled',
                    db: `
                    disabled
                    cognito_id
                    cognito_confirmed
                    groups {
                        group {
                            disabled
                            start_date
                            end_date
                        }
                    }`,
                    label: 'Statut',
                    sortable: true,
                    formatter: (_value, _key, user) => {
                        // Get user state label
                        let stateLabel = userFieldsValues.stateMap[getUserState(user)]?.label || '-'

                        // Add user group state label if needed
                        const groupState = getUserGroupState(user)

                        if (groupState === 'disabled') {
                            stateLabel += ' - Promotion suspendue'
                        } else if (groupState === 'inactive') {
                            stateLabel += ' - Promotion inactive'
                        }

                        return stateLabel
                    }
                },
                {
                    key: 'user_logs',
                    db: `
                    user_logs(where: {type: {slug: {_in: ["module", "course"]}}}) {
                        data_key
                        data
                        type {
                            slug
                        }
                    }`,
                    label: 'Progression',
                    sortable: true,
                    formatter: (logs) => {
                        const progressData = getUserProgressData(logs)

                        if (progressData.hasDoneLevel1) {
                            return 'Formation terminée'
                        } else if (progressData.level1Progress > 0) {
                            return `${progressData.level1Progress}/${this.level1.modules_aggregate.aggregate.count}`
                        }

                        return '-'
                    }
                }
            ]

            if (this.isSuperAdmin) {
                fields.splice(5, 0,
                    {
                        key: 'groups',
                        db: `groups {
                            group {
                                customer {
                                    identifier
                                    name
                                }
                            }
                        }`,
                        label: 'Client(s)',
                        sortable: true,
                        formatter: (values) => {
                            if (!values || values.length <= 0)
                                return 'Aucun'

                            return values.map(value => [value.group?.customer?.identifier, value.group?.customer?.name].filter(str => str || false).join(' - ')).join(', ') || '~ Client supprimée ~'
                        }
                    }
                )
            }

            return fields
        },
        managedCustomer() {
            if (!this.isUserManager) {
                return null
            }

            return (this.userInfo && this.userInfo.managed_groups?.[0]?.customer) || null
        },
    },
    async mounted() {
        this.isLoading = true

        this.level1 = await this.$store.dispatch('Course/GetCourseByType', 'simple')

        this.isLoading = false
    },
    methods:{
        ...mapActions('EditItem', ['clearCurrentID']),
        addUser(){
            this.clearCurrentID();
            this.$router.push({name:'users-create'});
        },
        importUserList() {
            this.isImportModalOpen = true
        },
        filterUser(users) {
            return users.filter((user) => {
                return (user.role === 'user')
            })
        },
    }
}
</script>
<style lang="scss">
// .user-buttons{
//     flex-basis: 33%;
// }
</style>