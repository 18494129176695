import userFieldsValues from "@/constants/userFieldsValues"

export default {
	name: 'Données pédagogiques',
	content: [
		{ 
			cells() {
				const base = [ 'CLIENT', '', 'PROMOTION', '', 'COMPLÉTION', '', '' ]
				
				// Add name and an empty cells for each job
				const jobCells = userFieldsValues.job.reduce((list, job) => {
					list.push((job.labelShort || job.label).toUpperCase())
					list.push('')
					list.push('')

					return list
				}, [])

				return base.concat(jobCells)
			}
		},
		{
			table: 'customersGroups',
			headers: () => {
				const base = [ 'ID Client', 'Client', 'ID Promotion', 'Promotion', 'En cours', 'Niv.1 terminé', 'Niv.2 terminé' ]

				// Add count and active time for each job
				const jobHeaders = userFieldsValues.job.reduce((list) => {
					list.push('En cours')
					list.push('Niv.1 terminé')
					list.push('Niv.2 terminé')

					return list
				}, [])

				return base.concat(jobHeaders)
			},
			row: (customerGroup) => {
				// Add count and active time for each job
				const byJob = userFieldsValues.job.reduce((list, job) => {
					const jobData = customerGroup.usersData.byJob[job.value]

					list.push({ value: (jobData?.countByProgress.isInProgress || 0) })
					list.push({ value: (jobData?.countByProgress.hasDoneLevel1 || 0) })
					list.push({ value: (jobData?.countByProgress.hasDoneLevel2 || 0) })

					return list
				}, [])

				return [
					{ value: customerGroup.customer.identifier },
					{ value: customerGroup.customer.name },
					'identifier',
					'name',
					{ value: (customerGroup.usersData.countByProgress.isInProgress || 0) },
					{ value: (customerGroup.usersData.countByProgress.hasDoneLevel1 || 0) },
					{ value: (customerGroup.usersData.countByProgress.hasDoneLevel2 || 0) },
				].concat(byJob)
			},
		}
	]
}