<template>
	<div class="w-full question flex flex-row relative justify-center items-center">
		<div class="handle self-stretch w-10 flex justify-center items-center cursor-move">
			<div class="rotate-icon">
				<b-icon-justify />
			</div>
		</div>

		<div class="flex items-center flex-1">
			<div
				class="handle cursor-pointer number bg-principal-selected w-10 self-stretch flex justify-center items-center text-xs"
			>
				<div>{{ number + 1 }}</div>
			</div>

			<div class="content p-10 w-full">
				<div class="input-field w-full">
					<!-- Types -->
					<p class="mb-2">Type de sondage</p>
					<div class="mb-4">
						<label
							v-for="type in questionTypes"
							:key="type.slug"
							class="mr-4 cursor-pointer"
						>
							<input
								type="radio"
								:name="`type-${uuid}`"
								:value="type.id"
								v-model="questionTypeId"
							>
							{{ type.name }}
						</label>
					</div>

					<!-- Image -->
					<div
						class="image-content text-center mb-4"
						@dragover="allowDrop"
						@drop.prevent.stop="dropImageInQuestion($event)"
						@click="openMediaChoice"
					>
						<div
							v-if="!mediaId"
							class="drop-zone p-10 w-full h-full border-dashed border-2 cursor-pointer"
						>
							<div class="m-auto w-1/3 break-words text-center text-xs">
								Ajouter une image depuis la bibliothèque de média
							</div>
						</div>
						<div v-else class="relative image inline-block m-auto h-2/3">
							<div
								class="w-4 h-4 absolute -right-2 -top-2 cursor-pointer pt-1"
								@click.stop="clearMedia"
							>
								<img src="@/assets/images/NODE_CROSS.svg" />
							</div>
							<img
								class="cursor-pointer"
								:src="assetsById[mediaId] && assetsById[mediaId].thumbnail_url"
							/>
						</div>
					</div>

					<!-- Label -->
					<b-form-textarea
						max-rows="2"
						type="text"
						placeholder="Énoncé de la question"
						v-model="questionLabel"
					/>
				</div>

				<div class="flex items-center mt-4">
					<label class="uppercase font-principal-medium text-sm align-middle h-4 mb-0 mr-2">
						Réponse obligatoire
					</label>
					<Toggle
						:checked="isRequired"
						:onInput="() => isRequired = !isRequired"
					/>
				</div>

				<!-- Answers -->
				<div
					v-show="hasAnwsers"
					class="answers w-full flex flex-row justify-center items-center mt-5"
				>
					<!-- Answer text -->
					<div
						class="box-answers w-full"
						:class="{ 'w-0': (answers.length == 0) }"
					>
						<draggable v-model="answers" handle=".answer-handle">
							<div
								v-for="(answer, i) in answers"
								:key="i"
								class="w-full flex flex-row justify-center items-center"
							>
								<!-- Number -->
								<div class="answer-handle cursor-pointer w-10 flex items-center justify-center">
									<div class="rotate-icon">
										<b-icon-justify></b-icon-justify>
									</div>
								</div>

								<div
									class="number answer-handle w-10 text-center self-stretch p-2 cursor-pointer flex justify-center items-center"
								>
									<div>{{ i + 1 }}</div>
								</div>

								<!-- Input/Text -->
								<div class="input-field w-full flex justify-around items-center p-2">
									<b-form-textarea
										max-rows="6"
										class="h-full border-none"
										:class="{ 'w-1/2': hasMediaInAnswer }" 
										@input="updateAnswerText($event, i)"
										type="text"
										placeholder="Réponse"
										:value="answer.text"
									/>

									<v-select
										v-show="hasMediaInAnswer"
										class="twn-select w-1/3 ml-auto mr-4"
										placeholder="Image"
										:options="images"
										label="name"
										:reduce="medium => medium.id"
										:value="answer.media_id"
										@input="updateAnswerMedium($event, i)"
									/>
								</div>

								<!-- Delete button -->
								<div
									v-show="canDeleteAnswer"
									class="bg-principal-selected cursor-pointer self-stretch flex items-center text-white justify-center w-10"
									@click="removeAnswer(i)"
								>
									<b-icon-x></b-icon-x>
								</div>
							</div>
						</draggable>
					</div>

					<!-- New answer button -->
					<div v-show="canAddAnswer" class="ml-2" :class="{ 'w-full': (answers.length == 0) }">
						<div
							@click="addDefaultAnswer(number)"
							class="plus m-auto rounded-full w-10 h-10 flex justify-center items-center cursor-pointer"
						>
							+
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			@click="removeQuestion"
			class="cursor-pointer deleteQuestion self-stretch w-10 flex justify-center items-center text-white"
		>
			<b-icon-x></b-icon-x>
		</div>
	</div>
</template>
<script>

import { mapState } from 'vuex'

import Draggable from 'vuedraggable'
import dispatchStoreRequest from '@/mixins/dispatchStoreRequest'

import Toggle from '@/components/Toggle'

const surveyQuestionTypeSlugs = ['closed', 'open', 'likert', 'smiley']
const surveyQuestionTypeConfig = {
	closed: {
		noMediaInAnswer: true,
	},
	open: {
		answerCountLimits: {
			min: 0,
			max: 0,
		},
	},
	likert: {
		answerCountLimits: {
			min: 3,
			max: 7,
		},
		noMediaInAnswer: true,
	},
	smiley: {
		answerCountLimits: {
			min: 0,
			max: 0,
		},
	},
}

let uuid = 0

export default {
	name: 'SurveyQuestion',
	components: {
		Draggable,
		Toggle,
	},
	mixins: [dispatchStoreRequest],
	props: {
		number: {
			type: Number,
			required: true,
			default: null,
		},
		question: {
			type: Object,
			required: true,
			default: null,
		},
	},
	computed: {
		...mapState('Assets', {
			assets: (state) => state.assets,
			images: (state) => state.assets.filter((asset) => {
				return (asset.type.slug === 'image')
			}),
			assetsById(state) {
				if (!state.assets || state.assets.length <= 0)
					return {}

				return state.assets.reduce((dict, asset) => {
					dict[asset.id] = asset

					return dict
				}, {})
			},
		}),
		...mapState('Games', {
			questionTypes(state){
				if (!state.question_type_list || state.question_type_list.length <= 0)
					return {}

				return state.question_type_list.filter((questionType) => {
					return (surveyQuestionTypeSlugs.indexOf(questionType.slug) > -1)
				})
			},
		}),
		questionType() {
			return this.questionTypes.find((questionType) => {
				return (questionType.id === this.questionTypeId)
			})
		},
		questionTypeConfig() {
			return surveyQuestionTypeConfig[this.questionType?.slug]
		},
		hasAnwsers() {
			return (!this.questionTypeConfig || !this.questionTypeConfig.answerCountLimits || this.questionTypeConfig.answerCountLimits.max > 0)
		},
		hasMediaInAnswer() {
			return (!this.questionTypeConfig || !this.questionTypeConfig.noMediaInAnswer)
		},
		answerCountLimits() {
			if (!this.questionTypeConfig || !this.questionTypeConfig.answerCountLimits) {
				return null
			}

			return this.questionTypeConfig.answerCountLimits
		},
		canAddAnswer() {
			if (!this.answerCountLimits) {
				return true
			}

			return (this.answers.length < this.answerCountLimits.max)
		},
		canDeleteAnswer() {
			if (!this.answerCountLimits) {
				return true
			}

			return (this.answers.length > this.answerCountLimits.min)
		},
		questionTypeId: {
			get() {
				return this.question.question_type_id
			},
			set(typeId) {
				this.$emit('update-question', this.number, {
					question_type_id: typeId
				})
			},
		},
		questionLabel: {
			get() {
				return this.question.text
			},
			set(value) {
				this.$emit('update-question', this.number, {
					text: value
				})
			},
		},
		isRequired: {
			get() {
				return this.question.required
			},
			set(required) {
				this.$emit('update-question', this.number, {
					required,
				})
			},
		},
		mediaId: {
			get() {
				return this.question.media_id
			},
			set(medium_id) {
				this.$emit('update-question', this.number, {
					media_id: medium_id
				})
			},
		},
		answers: {
			get() {
				return this.question.answers
			},
			set(value) {
				this.$emit('update-question', this.number, {
					answers: value
				})
			},
		},
	},
	watch: {
		questionTypeId: {
			handler(questionTypeId) {
				// Automaticly set default question type
				if (!questionTypeId && this.questionTypes?.length > 0 && this.questionTypes[0].id) {
					this.questionTypeId = this.questionTypes[0].id
				}
			},
			immediate: true,
		},
		questionTypes: {
			handler(questionTypes) {
				// Automaticly set default question type
				if (!this.questionTypeId && questionTypes?.length > 0 && questionTypes[0].id) {
					this.questionTypeId = questionTypes[0].id
				}
			},
			immediate: true,
		},
		answerCountLimits: {
			handler(answerCountLimits) {
				// Automaticly set default question type
				if (answerCountLimits) {
					if (this.answers.length < answerCountLimits.min) {
						for (let i = this.answers.length; i < answerCountLimits.min; i += 1) {
							this.addDefaultAnswer()
						}
					}

					if (this.answers.length > answerCountLimits.max) {
						for (let i = this.answers.length - 1; i >= answerCountLimits.max; i -= 1) {
							this.removeAnswer(i)
						}
					}
				}
			},
			immediate: true,
		},
	},
	beforeCreate() {
		this.uuid = uuid.toString()
		uuid += 1
	},
	methods: {
		getMediaLabel(medium) {
			return [medium.title, medium.name].filter(str => str || false).join(' - ')
		},
		updateAnswerText(input, i) {
			let newAnswers = [...this.answers]
			newAnswers[i].text = input
			this.answers = newAnswers
		},
		updateAnswerMedium(newValue, i) {
			let newAnswers = [...this.answers]
			newAnswers[i].media_id = newValue
			this.answers = newAnswers
		},
		removeQuestion() {
			this.$emit('delete-question', this.number)
		},
		addDefaultAnswer() {
			let newAnswers = [...this.answers]
			newAnswers.push({
				text: '',
				is_correct: false
			})
			this.$emit('update-question', this.number, {
				answers: newAnswers
			})
		},
		checkAnswer(index) {
			let correct = true
			let newAnswers = [...this.answers]

			if (!this.options || this.options.hasMultipleAnswers === undefined) {
				if (newAnswers[index].is_correct) {
					correct = false
				}
				newAnswers[index].is_correct = correct
			} else {
				for (var i = 0; i < newAnswers.length; i++) {
					newAnswers[i].is_correct = false

					if (this.options && this.options.multipleCorrection) {
						newAnswers[i].correction = this.wrongCorrectionText
					}
				}

				newAnswers[index].is_correct = true
				if (this.options && this.options.multipleCorrection) {
					newAnswers[index].correction = this.rightCorrectionText
				}
			}
			
			this.answers = newAnswers
		},
		removeAnswer(i) {
			let newAnswers = [...this.answers]
			newAnswers.splice(i, 1)
			this.answers = newAnswers
		},
		openMediaChoice() {
			this.$emit('open-panel')
		},
		async dropImageInQuestion(e) {
			if (e.dataTransfer) {
				const assetJSON = e.dataTransfer.getData('application/json')
				const asset = assetJSON ? JSON.parse(assetJSON) : null

				this.$emit('update-question', this.number, {
					media_id: asset.id
				})
			}
		},
		clearMedia() {
			this.$emit('update-question', this.number, {
				media_id: null
			})
		},
		allowDrop: function(event) {
			event.preventDefault()
		},
	},
}
</script>

<style lang="scss">
	.plus {
		background-color: rgba(225, 225, 225, 0.15);
	}

	.question:not(.no-style) {
		box-shadow: $cardShadow;
		border-radius: 10px;

		&.removeState {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}

		color: $textLight;
		.number {
			background-color: rgba(220, 151, 153, 0.1);
		}

		.rotate-icon {
			transform: rotate(90deg);
		}

		.image-content {
			.image {
				img {
					max-height: 150px;
				}
			}
		}

		.deleteQuestion {
			@apply bg-principal-selected;
		}

		.answers {
			.box-answers {
				box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
			}
			.remove-state {
				> :first-child {
					@apply bg-principal-selected text-white;
				}
				> :last-child {
					@apply border-principal-selected;
				}
			}
			.input-field {
				border: solid 0.3px #e1e1e1;
				border-left: none;
				
				textarea {
					height: 1.4rem;
				}

				&.no-handle {
					border: solid 0.3px #e1e1e1;
				}
			}

			/* The switch - the box around the slider */
			.switch {
				@apply text-center cursor-pointer;
				position: relative;
				display: inline-block;
				width: 50px;
				height: 24px;
				margin-bottom: 0;
			}

			.switch input[type="radio"] {
				@apply appearance-none border-2 rounded-full w-4 h-4 cursor-pointer;
				border-color: #CCC;

				&:checked {
					@apply bg-red border-red;
				}
			}

			/* Hide default HTML checkbox */
			.switch input[type="checkbox"] {
				opacity: 0;
				width: 0;
				height: 0;
			}

			/* The slider */
			.slider {
				position: absolute;
				cursor: pointer;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: #ccc;
				-webkit-transition: 0.4s;
				transition: 0.4s;
			}

			.slider:before {
				position: absolute;
				content: "";
				height: 16px;
				width: 16px;
				left: 4px;
				bottom: 4px;
				background-color: white;
				-webkit-transition: 0.4s;
				transition: 0.4s;
			}

			input:checked + .slider {
				@apply bg-principal-selected;
			}

			input:checked + .slider:before {
				-webkit-transform: translateX(26px);
				-ms-transform: translateX(26px);
				transform: translateX(26px);
			}

			/* Rounded sliders */
			.slider.round {
				border-radius: 24px;
			}

			.slider.round:before {
				border-radius: 50%;
			}
		}
	}
</style>
