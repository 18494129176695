<template>
	<div class="dashboard-main">
		<!-- Header -->
		<div class="flex items-center justify-between">
			<div>
				<Toggle
				v-if="isSuperAdmin"
				:checked="includeOtherRoles"
				:onInput="() => includeOtherRoles = !includeOtherRoles"></Toggle>
				<span v-if="isSuperAdmin" class="ml-2">Inclure les admins/responsables clients</span>
			</div>
			<button type="button" class="mb-4 twn-button" @click="ExportReport" v-bind:disabled="isLoading">
				<span v-if="!isLoading">
					Exporter le rapport
				</span>
				<span v-else>
					Chargement...
				</span>
			</button>
		</div>

		<!-- Dashboard -->
		<p v-if="isLoading || !dashboardData">
			Chargement...
		</p>
		<div v-else>
			<!-- Users count and state data -->
			<CategoryBlock title="Utilisateurs">
				<!-- KPIs -->
				<div class="inline-block w-2/3 align-top pr-8">
					<div class="dashboard-main-kpis">
						<KPIBlock
						title="Utilisateurs actifs"
						help="Utilisateurs ayant choisi leur mot de passe"
						:number="dashboardData.usersDashboardData.active"
						color="green" />
						<KPIBlock
						title="Utilisateurs inactifs"
						help="Utilisateurs ayant reçu leur mail d'invitation, mais qui n'ont pas encore choisi leur mot de passe"
						:number="dashboardData.usersDashboardData.inactive"
						color="red" />
						<KPIBlock
						title="Complétion globale"
						:percentage="dashboardData.usersDashboardData.hasFinished"
						color="blue-dark" />
					</div>

					<div class="dashboard-main-kpis">
						<KPIBlock
						title="Utilisateurs inscrits"
						help="Nombre total d'utilisateurs tous statuts confondus"
						:number="dashboardData.usersDashboardData.total" />
						<KPIBlock
						title="Utilisateurs en attente"
						help="Utilisateurs qui n'ont pas encore reçu leur mail d'invitation"
						:number="dashboardData.usersDashboardData.pending" />
						<KPIBlock
						title="Utilisateurs suspendus"
						:number="dashboardData.usersDashboardData.disabled" />
					</div>
				</div>

				<!-- Users distribution -->
				<SubCategoryBlock class="inline-block w-1/3 align-top" title="Répartition par métier">
					<PieChart :data="dashboardData.usersDashboardData.percentByJob" />
				</SubCategoryBlock>
			</CategoryBlock>

			<!-- Users activity data -->
			<CategoryBlock title="Données de connexion">
				<!-- Active users -->
				<SubCategoryBlock class="dashboard-main-active-user mb-8" title="Nombre d'utilisateurs actifs par">
					<div class="inline-block ml-6">
						<span class="mr-2">Mois</span>
						<Toggle
						:checked="!showUserActivityByMonth"
						:onInput="() => showUserActivityByMonth = !showUserActivityByMonth"></Toggle>
						<span class="ml-2">Années</span>
					</div>

					<LineChart :data="dashboardData.userActivityDashboardData.activeCount[(showUserActivityByMonth ? 'months' : 'years')]" />
				</SubCategoryBlock>

				<!-- Active users times -->
				<div class="flex">
					<SubCategoryBlock class="flex-1 mr-4" title="Durée totale de connexion">
						<BarPieChart
						class="mt-12"
						:data="dashboardData.userActivityDashboardData.activeTime.sum" />
					</SubCategoryBlock>

					<SubCategoryBlock class="flex-1 ml-4" title="Durée moyenne de connexion">
						<BarChart
						:data="dashboardData.userActivityDashboardData.activeTime.average"
						format="time" />
					</SubCategoryBlock>
				</div>
			</CategoryBlock>

			<!-- Users progression data -->
			<CategoryBlock title="Données pédagogiques">
				<!-- Users count by status -->
				<SubCategoryBlock class="mb-8" title="Progression des utilisateurs selon le métier">
					<BarChart
					:data="dashboardData.userProgressionDashboardData.userCounts"
					:enable-tooltip="true"
					:horizontal-bars="true"
					:stack-bars="true"
					:show-stack-sum="true"
					:labels="userCountsLabels" />
				</SubCategoryBlock>

				<!-- Users time to finish distribution -->
				<SubCategoryBlock title="Taux de complétion du niveau 2 dans la durée">
					<div class="dashboard-main-kpis pt-4 pb-5">
						<KPIBlock title="J+1 mois" :percentage="dashboardData.userProgressionDashboardData.hasFinishedIn.oneMonth" color="gray-lighter" text-color="dashboard-blue-dark" />
						<KPIBlock title="J+3 mois" :percentage="dashboardData.userProgressionDashboardData.hasFinishedIn.threeMonth" color="gray-lighter" text-color="dashboard-blue-dark" />
						<KPIBlock title="J+1 an" :percentage="dashboardData.userProgressionDashboardData.hasFinishedIn.oneYear" color="gray-lighter" text-color="dashboard-blue-dark" />
						<div class="flex-1"></div>
						<div class="flex-1"></div>
						<div class="flex-1"></div>
					</div>

					<div class="dashboard-main-finish-by-job">
						<div
						:style="inlineBarChartStyle(index, dashboardData.userProgressionDashboardData.hasFinishedIn.byJob)"
						:class="{ 'flex-1': (index > 0)}"
						v-for="(jobData, jobSlug, index) in dashboardData.userProgressionDashboardData.hasFinishedIn.byJob"
						:key="jobSlug">
							<BarChart
							:data="jobData"
							:high-value="1"
							:serie-name="jobSlug"
							:horizontal-bars="true"
							:show-legend="false"
							:labels="(index == 0 ? hasFinishedInLabels : null)"
							format="percentage" />
							<p class="ct-label">{{jobs[jobSlug]}}</p>
						</div>
					</div>
				</SubCategoryBlock>
			</CategoryBlock>

		</div>
	</div>
</template>

<script>
	import trackingReport from '@/utils/trackingReport'

	import userFieldsValues from "@/constants/userFieldsValues"
	import template from "@/constants/mainTrackingReportTemplate"

	import Toggle from '@/components/Toggle'

	import CategoryBlock from "./block/Category"
	import SubCategoryBlock from "./block/SubCategory"
	import KPIBlock from "./block/KPI"
	import PieChart from "./chart/Pie"
	import LineChart from "./chart/Line"
	import BarChart from "./chart/Bar"
	import BarPieChart from "./chart/BarPie"

	export default {
		name: 'MainDashboard',
		components: {
			Toggle,
			CategoryBlock,
			SubCategoryBlock,
			KPIBlock,
			PieChart,
			LineChart,
			BarChart,
			BarPieChart
		},
		data() {
			return {
				isLoading: false,
				dashboardData: null,
				includeOtherRoles: false,
				showUserActivityByMonth: true,
				jobs: userFieldsValues.job.reduce((dict, job) => {
					dict[job.value] = (job.labelShort || job.label)

					return dict
				}, {})
			}
		},
		computed: {
			isSuperAdmin() {
				const userInfo = this.$store.state.Auth.userInfo

				return (userInfo && userInfo.role == 'superadmin')
			},
			userCountsLabels() {
				const keys = Object.keys(this.dashboardData.userProgressionDashboardData.userCounts)

				const labels = {
					hasNotStarted: 'utilisateurs inactifs',
					isInProgress: 'utilisateurs en cours',
					hasDoneLevel1: 'niveau 01 terminé',
					hasDoneLevel2: 'niveau 02 terminé'
				}

				return keys.map((key) => (labels[key] || key))
			},
			hasFinishedInLabels() {
				return ['J+1 mois', 'J+3 mois', 'J+1 an']
			}
		},
		watch: {
			async includeOtherRoles(includeOtherRoles) {
				this.isLoading = true

				// Load tracking data
				let payload = null

				if (includeOtherRoles) {
					payload = { roles: ['user', 'superadmin', 'customer_manager'] }
				}

				this.dashboardData = await trackingReport.loadData(this.$store, template, payload)

				this.isLoading = false
			}
		},
		async mounted() {
			this.isLoading = true

			// Load tracking data
			this.dashboardData = await trackingReport.loadData(this.$store, template)

			this.isLoading = false
		},
		methods: {
			inlineBarChartStyle(index, data) {
				if (index > 0)
					return ''

				return 'width: calc(' + (100 / Object.keys(data).length) + '% + 100px);'
			},
			async ExportReport() {
				this.isLoading = true
				
				try {
					await trackingReport.export('', this.$store, template, null, this.dashboardData)
				} catch (err) {
					// console.error(err)
				}

				this.isLoading = false
			}
		}
	}
</script>

<style lang="scss">
	.dashboard-main {
		@apply max-w-screen-lg mx-auto;

		&-kpis {
			@apply flex flex-wrap justify-between;

			&:first-child {
				@apply mb-8;
			}
		}

		.dashboard-category {
			@apply w-full mb-5;

			.dashboard-kpi {
				@apply flex-1 mx-4;

				&:first-child {
					@apply ml-0;
				}

				&:last-child {
					@apply mr-0;
				}
			}
		}

		&-active-user {
			& > p {
				display: inline-block;
			}
		}

		&-finish-by-job {
			@apply flex;
			padding-left: 100px;

			.dashboard-chart {
				@apply pr-8 mb-3;
			}

			& > div:first-child {
				margin-left: -100px;

				p.ct-label {
					margin-left: 100px;
				}
			}

			p.ct-label {
				@apply text-dashboard-blue-dark;

				font-style: italic;
			}
		}
	}
</style>