<template>
	<b-modal size="lg" v-model="isOpen" hide-footer @hide="resetFile" title="Importer des utilisateurs">
		<!-- Error/info message -->
		<p v-if="info.message" :class="`text-${info.color}-600 text-sm mb-4 text-center`">{{info.message}}</p>

		<!-- Choose file block -->
		<div class="flex justify-center items-center">
			<button @click="$refs.inputFile.click()" type="button" class="mr-2 twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs">Choisir un fichier</button>
			<span v-if="selectedFile">{{selectedFile.name}}</span>
			<input type="file" ref="inputFile" hidden class="input-file" @click="$event.target.value = null" @change="fileSelected">
		</div>

		<!-- File format summary -->
		<p class="text-xs my-4">
			Rappel : le fichier doit contenir dans l'ordre les colonnes email, nom, prénom.
			<br>
			<a class="text-sm underline" :href="require('@/assets/files/userImportSample.xls')" download="modele_import_utilisateurs.xls">Télécharger un fichier d'exemple</a>
		</p>

		<!-- Loading and file errors details -->
		<p class="text-center" v-if="loading">Chargement...</p>
		<ul v-if="fileErrors.length" class="mb-4">
			<li v-for="(err, index) in fileErrors" :key="index" class="text-red-600">- {{err}}</li>
		</ul>

		<!-- Data preview -->
		<p v-if="dataPreview && dataPreview.length > 0" class="font-bold text-center text-sm">Aperçu</p>
		<table class="text-xs mt-2 mx-auto" v-if="dataPreview && dataPreview.length > 0">
			<tbody>
				<tr>
					<th class="py-1 pr-2">email</th>
					<td class="px-2" v-for="(user, index) in dataPreview" :key="index">{{user['email'] || '-'}}</td>
				</tr>
				<tr>
					<th class="py-1 pr-2">nom</th>
					<td class="px-2" v-for="(user, index) in dataPreview" :key="index">{{user['nom'] || '-'}}</td>
				</tr>
				<tr>
					<th class="py-1 pr-2">prenom</th>
					<td class="px-2" v-for="(user, index) in dataPreview" :key="index">{{user['prénom'] || '-'}}</td>
				</tr>
			</tbody>
		</table>

		<!-- New users count -->
		<p v-if="jsonData && isFileValid" class="text-center font-bold text-sm">{{jsonData.length}} nouveau{{jsonData.length > 1 ? 'x' : 	''}} utilisateur{{jsonData.length > 1 ? 's' : 	''}} à importer</p>

		<!-- Group choice -->
		<div v-if="!defaultGroup" class="my-4 mx-auto w-3/4">
			<label class="w-1/5" for="users-group">Promotion :</label>
			<v-select
			id="users-group"
			class="twn-select inline-block w-4/5"
			placeholder="Rechercher une promotion..."
			:options="groupList"
			:reduce="group => group.id"
			:getOptionLabel="getGroupLabel"
			v-model="selectedGroup" />
		</div>

		<!-- Import button -->
		<button v-if="isFileValid" :disabled="jsonData.length <= 0" @click="importList" type="button" class="mt-2 mx-auto twn-button hidden md:block whitespace-no-wrap overflow-hidden text-xs">Importer la liste</button>
	</b-modal>
</template>

<script>
	import { mapActions, mapState } from 'vuex'
	import XLSX from 'xlsx'

	import dispatchStoreRequest from "@/mixins/dispatchStoreRequest"
	
	export default {
		name: 'UserImportModal',
		mixins: [ dispatchStoreRequest ],
		props: {
			value: {
				type: Boolean,
				default: false
			},
			defaultGroup: {
				type: String,
				default: null,
			},
		},
		data() {
			return {
				selectedFile: null,
				selectedGroup: (this.defaultGroup || null),
				allowedExt: ['xlsx', 'xls'],
				fileErrors: [],
				isFileValid: false,
				statusAlias: {'locataire': 1, 'copropriétaire': 2, 'autre': 3},
				info: {color: "", message: ""},
				dataPreview: [],
				loading: false,
				jsonData: null
			}
		},
		computed: {
			...mapState('Auth', [ 'userInfo' ]),
			...mapState('Utils', ['userList']),
			...mapState({
				groupList: state => state.Promotion.list
			}),
			isOpen: {
				get() {
					return this.value
				},
				set(value) {
					this.$emit('input', value)
				}
			},
			isUserManager() {
				return (this.userInfo && this.userInfo.role == 'customer_manager')
			},
			managedCustomer() {
				if (!this.isUserManager) {
					return null
				}

				return (this.userInfo && this.userInfo.managed_groups?.[0]?.customer) || null
			},
		},
		watch: {
			defaultGroup: {
				handler(group) {
					if (group) {
						this.selectedGroup = group
					}
				},
				immediate: true,
			}
		},
		async mounted() {
			await this.dispatchStoreRequest('Promotion/getList')
		},
		methods: {
			...mapActions('Users', ['importUsers']),
			getGroupLabel(option) {
				if (!option)
					return 'Promotion supprimé'

				return [option.customer && option.customer.name, option.identifier, option.name].filter(str => str || false).join(' - ') || '~ Promotion sans titre ou supprimé ~'
			},
			fileSelected(e) {
				this.loading = true

				// Reset
				this.info.message = ""
				this.info.color = ''
				this.fileErrors = []
				this.dataPreview = []
				this.jsonData = null
				this.isFileValid = false

				// Get file from input
				this.selectedFile = e.target.files[0]
				
				// Check file extension
				const ext = this.selectedFile.name.split('.').pop().toLowerCase()

				if (!this.allowedExt.includes(ext)) {
					this.info.message = 'Veuillez choisir un fichier au format XLSX ou XLS'
					this.info.color = 'red'
					this.loading = false
					return 
				}

				// Read file to load and check data and generate a preview
				const reader = new FileReader()
				reader.onload = this.onFileLoad
				reader.onerror = () => {
					this.info.message = 'Erreur de lecture du fichier'
					this.info.color = 'red'
					this.isFileValid = false
					this.loading = false
				}
				reader.onabort = reader.onerror
				reader.readAsArrayBuffer(this.selectedFile)
			},
			async onFileLoad(e) {
				// Read file data
				const data = new Uint8Array(e.target.result)
				const workbook = XLSX.read(data, {type: 'array', codepage: 1147})
				
				// Get first sheet and convert his content to json
				const worksheet = workbook.Sheets[workbook.SheetNames[0]]
				let jsonData = XLSX.utils.sheet_to_json(worksheet)

				if (jsonData.length <= 0) {
					this.loading = false
					return
				}

				// Check data for invalid values
				const errors = this.checkFileDatas(worksheet, jsonData)

				if (errors.length > 0) {
					this.fileErrors = errors
					this.loading = false
					return
				}

				// Check data for duplicate lines
				const duplicates = this.findDuplicates(jsonData.filter(data => !!data.email))

				if (duplicates.length > 0) {
					this.fileErrors = duplicates.map(email => "Cette adresse mail à été trouvée plusieur fois dans le document : " + email)
					this.loading = false
					return
				}

				// Remove line with a registered user email
				jsonData = this.removeRegisteredUsers(jsonData)

				// Check quota for manager
				if (this.isUserManager && this.managedCustomer) {
					const users = (this.$store.state.Users.list.length && this.$store.state.Users.list) || await this.dispatchStoreRequest('Users/getList')
					const simpleUsers = users.filter((user) => {
						return (user.role === 'user')
					})

					if ((simpleUsers.length + jsonData.length) >= this.managedCustomer.quota) {
						this.fileErrors = ['Impossible d\'ajouter ' + jsonData.length + ' nouveaux utilisateurs, vous n\'avez pas assez d\'invitation disponible. Contactez l\'administrateur pour en obtenir de nouvelles.']
						this.loading = false
						return
					}
				}

				// Get one or two lines for data preview
				if (jsonData.length > 2) {
					this.dataPreview = [jsonData[0], jsonData[Math.round(jsonData.length / 2) - 1]]
				} else if (jsonData.length > 0) {
					this.dataPreview = [jsonData[0]]
				}

				this.jsonData = jsonData
				this.isFileValid = true
				this.loading = false
			},
			validateEmail(email) {
				const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

				return re.test(String(email).replace(/\s/g, '').toLowerCase())
			},
			checkFileDatas(sheet, users) {
				let errors = []

				// Check file header names
				const validHeaders = ['email', 'nom', 'prénom']
				let requiredHeaders = ['email', 'nom', 'prénom']

				for (let i = 0; i < validHeaders.length; i++) {
					const cellRef = XLSX.utils.encode_cell({c: i, r: 0})
					const col = sheet[cellRef]?.v

					if (col && validHeaders.indexOf(col) < 0) {
						errors.push('Colonne ' + i + ' inconnue : "' + col + '"')
					} else {
						const requiredHeaderIndex = requiredHeaders.indexOf(col)

						if (requiredHeaderIndex > -1) {
							requiredHeaders.splice(requiredHeaderIndex, 1)
						}
					}
				}

				// Check for missing column/header
				if (requiredHeaders.length > 0) {
					requiredHeaders.forEach((header) => {
						errors.push('Il manque la colonne "' + header + '"')
					})
					return errors
				}

				// Check values
				const requiredValueKeys = ['email', 'nom', 'prénom']

				users.forEach((user, i) => {
					const missingValueKeys = requiredValueKeys.filter((key) => !user[key])

					if (missingValueKeys.length > 0) {
						errors.push('Il manque une valeur obligatoire pour le champ ' + missingValueKeys.join(' et ') + ' sur la ligne ' + (i + 2))
					}

					if (user.email && !this.validateEmail(user.email)) {
						errors.push('Vérifiez le format de l\'email sur la ligne ' + (i + 2))
					}
				})

				return errors
			},
			findDuplicates(users) {
				const userEmails = {}
				const duplicates = {}

				users.forEach(user => {
					const email = user.email.replace(/\s/g, '').toLowerCase()

					if (userEmails[email]) {
						duplicates[email] = true
					} else {
						userEmails[email] = true
					}
				})

				return Object.keys(duplicates)
			},
			removeRegisteredUsers(users) {
				// Get a dictionnary of registered user emails from DB
				const registeredUserEmails = this.userList.reduce((dict, user) => {
					const email = user.email.replace(/\s/g, '').toLowerCase()

					dict[email] = true

					return dict
				}, {})

				// Filter out existing user emails in the provided user list
				return users.filter(user => {
					if (!user.email) {
						return false
					}

					const email = user.email.replace(/\s/g, '').toLowerCase()

					return !registeredUserEmails[email]
				})
			},
			async importList() {
				const usersData = []

				// Format each line
				for (const { email, nom: last_name, "prénom": first_name } of this.jsonData) {

					usersData.push({
						email: email ? email.replace(/\s/g, '').toLowerCase() : null,
						first_name,
						last_name,
						group_id: (this.selectedGroup || null)
					})
				}

				if (usersData.length > 0) {
					try {
						// Register users
						await this.importUsers(usersData)

						// Display confirmation message for 2 seconds
						this.info.message = 'La liste a bien été importé'
						this.info.color = 'green'
						this.resetFile()

						setTimeout(() => {
							this.info.message = ""
							this.info.color = ''
							this.$router.go()
						}, 2000)
					} catch (error) {
						// Display error message
						if (error.graphQLErrors[0].extensions.code === "constraint-violation") {
							this.info.message = "Une ou plusieurs adresses email sont déjà utilisé pour une inscription veuillez les retirer du document"
							this.info.color = 'red'
						} else {
							this.info.message = error.message
							this.info.color = 'red'
						}
						
					}
				}
			},
			resetFile() {
				this.selectedFile = null
				this.fileErrors = []
				this.$refs.inputFile.value = ""
				this.isFileValid = false
				this.dataPreview = []
				this.selectedGroup = (this.defaultGroup || null)
			}
		},
	}
</script>
