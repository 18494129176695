export default function mailingDashboardData(data) {
	const mailDateFromLog = (mailType) => {
		if (!data.user.mailingLogs[mailType]) {
			return null
		}

		return new Date(data.user.mailingLogs[mailType].created_at)
	}

	return {
		accessDate: (mailDateFromLog('access-before') || mailDateFromLog('access-late') || mailDateFromLog('access-active')),
		remindersDate: {
			noLogin: mailDateFromLog('reminder-no-login') || mailDateFromLog('reminder-no-login-active'),
			notFinishedAfterOneDay: mailDateFromLog('reminder-not-finished-day'),
			notFinishedAfterOneMonth: mailDateFromLog('reminder-not-finished-month')
		}
	}
}