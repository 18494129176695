import userFieldsValues from "@/constants/userFieldsValues"

export default {
	name: 'Emails',
	content: [
		{ 
			cells() {
				const base = [ 'CLIENT', '', 'PROMOTION', '', 'MAILS DE RELANCE', '', '' ]
				
				// Add name and an empty cells for each job
				const jobCells = userFieldsValues.job.reduce((list, job) => {
					list.push((job.labelShort || job.label).toUpperCase())
					list.push('')
					list.push('')

					return list
				}, [])

				return base.concat(jobCells)
			}
		},
		{
			table: 'customersGroups',
			headers: () => {
				const base = [ 'ID Client', 'Client', 'ID Promotion', 'Promotion', 'J+1 session ratée', 'J+1 non terminé', 'J+30 non terminé' ]

				// Add count and active time for each job
				const jobHeaders = userFieldsValues.job.reduce((list) => {
					list.push('J+1 session ratée')
					list.push('J+1 non terminé')
					list.push('J+30 non terminé')

					return list
				}, [])

				return base.concat(jobHeaders)
			},
			row: (customerGroup) => {
				// Add count and active time for each job
				const byJob = userFieldsValues.job.reduce((list, job) => {
					const jobData = customerGroup.usersData.byJob[job.value]

					list.push({ value: (jobData?.rateByMailType.noLogin || 0), type: 'percentage' })
					list.push({ value: (jobData?.rateByMailType.notFinishedAfterOneDay || 0), type: 'percentage' })
					list.push({ value: (jobData?.rateByMailType.notFinishedAfterOneMonth || 0), type: 'percentage' })

					return list
				}, [])

				return [
					{ value: customerGroup.customer.identifier },
					{ value: customerGroup.customer.name },
					'identifier',
					'name',
					{ value: (customerGroup.usersData.rateByMailType.noLogin || 0), type: 'percentage' },
					{ value: (customerGroup.usersData.rateByMailType.notFinishedAfterOneDay || 0), type: 'percentage' },
					{ value: (customerGroup.usersData.rateByMailType.notFinishedAfterOneMonth || 0), type: 'percentage' }
				].concat(byJob)
			},
		}
	]
}