const oneHour = (1 * 60 * 60 * 1000)
const fiveMinutes = (5 * 60 * 1000)

export default {
	mailingLogs(user) {
		// Create a map of type => log from the user mailer logs
		return user.logs.reduce((dict, log) => {
			if (log.type.slug == 'mail' && log.data.type) {
				dict[log.data.type] = log
			}

			return dict
		}, {})
	},
	progressionData(user, data) {
		// Init computed data
		let computedProgressionData = {
			hasStarted: false,
			level1Progress: 0,
			level1DoneTimestamp: null,
		}

		const courseProgress = {}

		user.logs.forEach((log) => {
			const logTime = (new Date(log.created_at)).getTime()

			if (!computedProgressionData.hasStarted && log.type.slug == 'activity') {
				computedProgressionData.hasStarted = true
			} else if (log.type.slug == 'course') {
				// Mark course has done and save timestamp
				if (log.data_key == data.coursesByType.simple.id && !computedProgressionData.level1DoneTimestamp) {
					computedProgressionData.level1DoneTimestamp = logTime
				}
			} else if (log.type.slug == 'module') {
				courseProgress[log.data_key] = true
			}
		})

		if (data.coursesByType.simple.modules.length > 0) {
			computedProgressionData.level1Progress = (Object.keys(courseProgress).length / data.coursesByType.simple.modules.length)
		}

		return computedProgressionData
	},
	activeTimeData(user) {
		// Init computed data
		let computedTimeData = {
			times: [],
			sum: 0,
			max: 0,
			average: 0,
			lastSessionTimestamp: 0
		}

		const sessionStops = {}

		// Get all event date times
		const times = user.logs.reduce((times, log) => {
			// Exclude "passive" logs
			if (['mail'].indexOf(log.type.slug) > -1) {
				return times
			}

			// Add log creation time
			const logTime = (new Date(log.created_at)).getTime()
			times.push(logTime)

			// Register all log time who should always trigger a new session
			if (['login', 'logout'].indexOf(log.type.slug) > -1) {
				sessionStops[logTime] = true
			}

			return times
		}, []).sort()

		// Get all data by spliting user logs in sessions
		let lastLogTime = 0

		times.forEach((logTime) => {
			const deltaTime = (logTime - lastLogTime)

			// Check if two logs are too far apart or if we need to create a new session
			if (deltaTime > oneHour || sessionStops[logTime]) {
				// Start a new session with the minimal time (5 min)
				computedTimeData.lastSessionTimestamp = logTime
				computedTimeData.times.push(fiveMinutes)

				// Update sum
				computedTimeData.sum += fiveMinutes
			} else {
				// Increment current session time
				computedTimeData.times[computedTimeData.times.length - 1] += deltaTime

				// Update sum
				computedTimeData.sum += deltaTime
			}

			// Update max
			const sessionTime = computedTimeData.times[computedTimeData.times.length - 1]

			if (sessionTime > computedTimeData.max) {
				computedTimeData.max = sessionTime
			}

			lastLogTime = logTime
		})

		// Compute average time
		if (computedTimeData.times.length > 0) {
			computedTimeData.average = (computedTimeData.sum / computedTimeData.times.length)
		}

		return computedTimeData
	}
}