import extraUsersData from "./extraUsersData"
import extraCoursesData from "../common/extraCoursesData"
import extraCustomersGroupsData from "../common/extraCustomersGroupsData"

import usersDashboardData from "./usersDashboardData"
import userActivityDashboardData from "./userActivityDashboardData"
import userProgressionDashboardData from "./userProgressionDashboardData"
import userMailingDashboardData from "./userMailingDashboardData"

import usageSheet from "../common/usageSheet"
import progressionSheet from "../common/progressionSheet"
import mailingSheet from "../common/mailingSheet"
import usersSheet from "../common/usersSheet"

// One day in ms
const oneDay = (1 * 60 * 60 * 1000 * 24)

export default {
	types: {
		float: '#0.00',
		percentage: '0.00%',
		duration: {
			format: '[h]"h" mm"min"',
			transform: (time) => (time / oneDay)
		},
		date: {
			format: 'dd/MM/yyyy "à" HH"h"mm',
			transform: (time) => (time ? ((time / (86400 * 1000)) + 25569) : false) // Excel datetime value format
		}
	},
	remoteData: {
		users: {
			module: 'Logs',
			action: 'GetGroupUsersTrackingData',
			payload: {
				roles: ['user'],
				logTypes: ['login', 'logout', 'mail', 'activity', 'scenario', 'course', 'module']
			},
			groupByUniq: 'id'
		},
		metaTypes: {
			module: 'Utils',
			action: 'initAll',
			state: 'metaTypeList',
			groupByUniq: 'slug'
		},
		courses: {
			module: 'Logs',
			action: 'GetCoursesTrackingData',
			groupByUniq: 'type.slug'
		},
		customersGroups: {
			module: 'Logs',
			action: 'GetGroupCustomerTrackingData',
			payload: {
				roles: ['user']
			},
			groupByUniq: 'id'
		}
	},
	extraData: {
		firstCreatedUserDate(data) {
			if (!data.users || data.users.length <= 0)
				return (new Date())

			let firstCreatedTime = (new Date(data.users[0].created_at)).getTime()

			data.users.forEach((user) => {
				const createdTime = (new Date(user.created_at)).getTime()

				if (createdTime < firstCreatedTime) {
					firstCreatedTime = createdTime
				}
			})

			return (new Date(firstCreatedTime))
		},
		courses: extraCoursesData,
		users: extraUsersData,
		confirmedUsers(data) {
			return data.users.filter((user) => {
				return user.cognito_confirmed
			})
		},
		customersGroups: extraCustomersGroupsData,
		usersDashboardData,
		userActivityDashboardData,
		userProgressionDashboardData,
		userMailingDashboardData
	},
	sheets: [
		usageSheet,
		progressionSheet,
		mailingSheet,
		usersSheet,
	]
}