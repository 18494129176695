<template>
	<div class="dashboard-chart dashboard-chart-pie">
		<!-- Chart -->
		<div class="dashboard-chart-container">
			<span class="dashboard-chart-tooltip" :class="{ 'opacity-0': !showTooltip }">{{tooltipValue}} %</span>

			<chartist
			ratio="ct-square"
			type="Pie"
			:data="chartData"
			:options="chartOptions"
			:event-handlers="chartEvents" />
		</div>

		<!-- Right legend -->
		<ul class="dashboard-chart-legend">
			<li
			v-for="serie in chartData.legend"
			:key="serie.name"
			:data-series-name="serie.name">
				{{serie.label}}
			</li>
		</ul>
	</div>
</template>

<script>
	import userFieldsValues from "@/constants/userFieldsValues"

	export default {
		name: 'PieChart',
		props: {
			data: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				chartOptions: {
					showLabel: false
				},
				chartEvents: [{
					event: 'draw',
					fn: this.onChartDraw
				}],
				tooltipValue: 0,
				showTooltip: false
			}
		},
		computed: {
			chartData() {
				const jobs = userFieldsValues.job.reduce((dict, job) => {
					dict[job.value] = (job.labelShort || job.label)

					return dict
				}, {})

				const dataKey = Object.keys(this.data)
				const formattedData = dataKey.reduce((formattedData, key) => {
					formattedData.legend.push({
						name: key,
						label: (jobs[key] || key)
					})
					formattedData.series.push({
						name: key,
						data: this.data[key]
					})

					return formattedData
				}, {
					legend: [],
					series: []
				})

				return formattedData
			}
		},
		methods: {
			onChartDraw(data) {
				if (data.type === 'slice') {
					const serieData = data.series.data
					const node = data.element.getNode()

					node.addEventListener('mouseover', () => {
						this.onSliceMouseOver(serieData)

						// Hack to place the hovered slice on top of the others
						data.element.parent().parent().append(data.element.parent())
					})
					node.addEventListener('mouseout', () => {
						this.onSliceMouseOut()
					})
				}
			},
			onSliceMouseOver(serieData) {
				this.tooltipValue = Math.round(serieData * 100)
				this.showTooltip = true
			},
			onSliceMouseOut() {
				this.showTooltip = false
			}
		}
	}
</script>

<style lang="scss">
	.dashboard-chart-pie {
		.dashboard-chart-container {
			@apply relative inline-block align-top text-center w-2/3 pt-2 pr-12;

			.ct-series path {
				@apply transition-stroke-width duration-500;
				stroke-width: 0;
				stroke-linejoin: round;
				stroke-linecap: round;
			}

			.ct-series path:hover {
				stroke-width: 4;
			}
		}

		.dashboard-chart-tooltip {
			@apply mb-2;
		}

		.dashboard-chart-legend {
			@apply inline-block align-top w-1/3 pt-10;
		}
	}
</style>