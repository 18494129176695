<template>
  <div>
    <List
      v-if="!isLoading"
      listing="group"
      :fields="fields"
      order-by="end_date: desc, start_date: asc"
      search-text="Rechercher une promotion"
      :pageSize="pageSize"
      edit-route-name="promotions-edit"
    >
      <template v-slot:buttons>
        <div class="md:flex flex-row justify-end hidden md:w-1/3">
          <button
            type="button"
            class="twn-button whitespace-no-wrap overflow-hidden text-xs mr-2"
            @click="onAddPromotionClick"
          >
            Ajouter une nouvelle promotion
          </button>
        </div>
      </template>
    </List>
  </div>
</template>

<script>
import List from "@/components/List";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    List,
  },
  props: {
    pageSize:{
      type: Number,
      default: 30,
      required: false
    }
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    ...mapState('Auth', [ 'userInfo' ]),
    isSuperAdmin() {
      return (this.userInfo && this.userInfo.role == 'superadmin')
    },
    fields() {
      const fields = [
        {
          key: "identifier",
          label: "Identifiant",
          sortable: true,
        },    
        {
          key: "name",
          label: "Nom",
          sortable: true,
        },
        {
          key: 'start_date',
          db: `start_date
          end_date`,
          label: "Dates",
          sortable: true,
          formatter: (_value, _key, item) => {
            const start = (item.start_date ? item.start_date.split('-').reverse().join('/') : '')
            const end = (item.end_date ? item.end_date.split('-').reverse().join('/') : '')

            return start + ' - ' + end
          }
        },
        {
          key: 'disabled',
          label: 'Statut',
          sortable: true,
          formatter: (value, _key, item) => {
            if (value)
              return 'Suspendue'

            const now = new Date()
            const start = new Date(item.start_date)
            const end = new Date(item.end_date)

            return (now < start ? 'À venir' : (now > end ? 'Passé' : 'En cours'))
          }
        },
        {
          key: 'users_aggregate',
          label: "Stagiaires",
          sortable: true,
          db: `users_aggregate(where: {
            user: {
              role: { _eq: "user" },
            }
          }) {
            aggregate {
              count
            }
          }`,
          nested: 'count'
        },
        {
          label: 'Actifs',
          sortable: true,
          key: 'actives',
          nested: 'count',
          db: `actives: users_aggregate(where: {user: {
            role: { _eq: "user" },
            cognito_confirmed: {_eq: true}}}) {
            aggregate {
              count
            }
          }`
        },
      ]

      if (this.isSuperAdmin) {
        fields.splice(2, 0,
          {
            key: 'customer',
            label: "Client",
            sortable: true,
            db: `customer {
              identifier
              name
            }`,
            formatter: (value) => {
              if (!value)
                return 'Aucun'

              return [value.identifier, value.name].filter(str => str || false).join(' - ') || '~ Client supprimé ~'
            }
          }
        )
      }

      return fields
    }
  },
  methods: {
    ...mapActions("EditItem", ["clearCurrentID"]),
    onAddPromotionClick() {
      this.clearCurrentID();
      this.$router.push({ name: "promotions-create" });
    },
  },
};
</script>