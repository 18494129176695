import userFieldsValues from "@/constants/userFieldsValues"

export default {
	name: 'Données de connexion',
	content: [
		{ 
			cells() {
				const base = [ 'CLIENT', '', 'PROMOTION', '', 'UTILISATEURS', '', '', '', '', '' ]
				
				// Add name and an empty cell for each job
				const jobCells = userFieldsValues.job.reduce((list, job) => {
					list.push((job.labelShort || job.label).toUpperCase())
					list.push('')

					return list
				}, [])

				return base.concat(jobCells)
			}
		},
		{
			table: 'customersGroups',
			headers: () => {
				const base = [ 'ID Client', 'Client', 'ID Promotion', 'Promotion', 'Inscrits', 'En attente', 'Désactivés', 'Actifs', 'Inactifs', 'Durée totale de connexion' ]

				// Add count and active time for each job
				const jobHeaders = userFieldsValues.job.reduce((list) => {
					list.push('Nombre')
					list.push('Durée de connexion')

					return list
				}, [])

				return base.concat(jobHeaders)
			},
			row: (customerGroup) => {
				// Add count and active time for each job
				const byJob = userFieldsValues.job.reduce((list, job) => {
					const jobData = customerGroup.usersData.byJob[job.value]

					list.push({ value: (jobData?.count || 0) })
					list.push({ value: (jobData?.activeTime || 0), type: 'duration' })

					return list
				}, [])

				return [
					{ value: customerGroup.customer.identifier },
					{ value: customerGroup.customer.name },
					'identifier',
					'name',
					{ value: (customerGroup.users.length || 0) },
					{ value: (customerGroup.usersData.countbyState.pending || 0) },
					{ value: (customerGroup.usersData.countbyState.disabled || 0) },
					{ value: (customerGroup.usersData.countbyState.active || 0) },
					{ value: (customerGroup.usersData.countbyState.inactive || 0) },
					{ value: (customerGroup.usersData.activeTime || 0), type: 'duration' }
				].concat(byJob)
			},
		}
	]
}