import userFieldsValues from "@/constants/userFieldsValues"

function mailDateFromLog(user, mailType) {
	if (!user.mailingLogs[mailType]) {
		return null
	}

	return new Date(user.mailingLogs[mailType].created_at)
}

export default {
	name: 'Utilisateurs',
	content: [
		{ 
			cells: [ 'CLIENT', '', 'PROMOTION', '', 'UTILISATEUR', '', '', '', '', '', '', '', 'NIVEAU 1', '', '', '', 'NIVEAU 2', '', '', '', 'MAILING' ],
		},
		{
			table: 'users',
			headers: [ 'ID Client', 'Client', 'ID Promotion', 'Promotion', 'Nom', 'Prénom', 'Email', 'Métier', 'Date d\'invitation', 'Dernière connexion', 'Nombre de connexion', 'Durée totale de connexion', 'Mission 1', 'Mission 2', 'Mission 3', 'Mission 4', 'Enquête 1', 'Enquête 2', 'Enquête 3', 'Enquête 4', 'J+1 Sessioon raté', 'J+1 non terminé', 'J+30 non terminé' ],
			row: (user, data) => {
				const userGroupId = user.groups?.[0]?.id
				const customerGroup = (userGroupId && data.customersGroupsById[userGroupId])

				const accessDate = (mailDateFromLog(user, 'access-before') || mailDateFromLog(user, 'access-late') || mailDateFromLog(user, 'access-active'))
				const noLogin = (mailDateFromLog(user, 'reminder-no-login') || mailDateFromLog(user, 'reminder-no-login-active'))
				const notFinishedAfterOneDay = mailDateFromLog(user, 'reminder-not-finished-day')
				const notFinishedAfterOneMonth = mailDateFromLog(user, 'reminder-not-finished-month')

				let moduleDoneDates = []

				for (let i = 0; i < 4; i += 1) {
					if (!user.progressionData.investigationLog[i]) {
						moduleDoneDates.push({ value: '-' })
						continue
					}

					const date = new Date(user.progressionData.investigationLog[i].created_at)

					moduleDoneDates.push({
						value: date.getTime(),
						type: 'date'
					})
				}

				for (let i = 0; i < 4; i += 1) {
					if (!user.progressionData.investigationLog[i]) {
						moduleDoneDates.push({ value: '-' })
						continue
					}

					const date = new Date(user.progressionData.investigationLog[i].created_at)

					moduleDoneDates.push({
						value: date.getTime(),
						type: 'date'
					})
				}

				return [
					{ value: (customerGroup?.customer.identifier || '') },
					{ value: (customerGroup?.customer.name || '') },
					{ value: (customerGroup?.identifier || '') },
					{ value: (customerGroup?.name || '') },
					'last_name',
					'first_name',
					'email',
					{ value: (userFieldsValues.jobMap[user.job] || '-') },
					{ value: (accessDate?.getTime() || '-'), type: (accessDate ? 'date' : undefined) },
					{ value: user.activeTimeData.lastSessionTimestamp, type: 'date' },
					{ value: user.activeTimeData.times.length },
					{ value: user.activeTimeData.sum, type: 'duration' },
				]
				.concat(moduleDoneDates)
				.concat([
					{ value: (noLogin?.getTime() || '-'), type: (noLogin ? 'date' : undefined) },
					{ value: (notFinishedAfterOneDay?.getTime() || '-'), type: (notFinishedAfterOneDay ? 'date' : undefined) },
					{ value: (notFinishedAfterOneMonth?.getTime() || '-'), type: (notFinishedAfterOneMonth ? 'date' : undefined) },
				])
			},
		}
	]
}