export function getUserProgressData(logs) {
	if (!logs || logs.length <= 0) {
		return {
			hasDoneLevel1: false,
			level1Progress: 0,
		}
	}

	// Get level and module progression
	let hasDoneLevel1 = 0
	let level1Progress = {}

	logs.forEach((log) => {
		switch (log.type.slug) {
			case 'module':
				level1Progress[log.data_key] = true
				break

			case 'course':
				hasDoneLevel1 = true
				break
		}
	})

	level1Progress = Object.keys(level1Progress).length

	return {
		hasDoneLevel1,
		level1Progress,
	}
}

export function getUserState(user) {
	if (user.disabled) {
		return 'disabled'
	} else if (!user.cognito_id && !user.cognito_confirmed) {
		return 'inactive'
	} else if (user.cognito_id) {
		if (user.cognito_confirmed) {
			return 'active'
		} else {
			return 'pending'
		}
	} else {
		return 'exist'
	}
}

export function getUserGroupState(user) {
	// Check if the user group is not disabled and still active (start/end date)
	if (user.groups?.length > 0) {
		if (user.groups[0].group.disabled) {
			return 'disabled'
		} else {
			const now = Date.now()
			const startDate = new Date(user.groups[0].group.start_date)
			const endDate = new Date(user.groups[0].group.end_date)

			if (now < startDate.getTime() || now > endDate.getTime()) {
				return 'inactive'
			}
		}
	}

	return null
}
