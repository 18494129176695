import gql from 'graphql-tag'

const GC_GET_USER_LIST = gql`
query GetUserList {
    user {
      id
      name: last_name
      first_name
      role
    }
  }
`

const GC_GET_USER_BY_ID = gql`
query UserByID($id: uuid!) {
  user_by_pk(id: $id) {
    id
    name: last_name
    first_name
    email
    role
    disabled
    cognito_id
    cognito_confirmed
    metas {
      id
      value
      meta_type_id
    }
    groups {
      id: group_id
      group {
        customer {
          name
        }
      }
    }
    logs: user_logs(where: {type: {slug: {_eq: "course"}}}, order_by: {created_at: asc}) {
      created_at
      data_key
      type {
        slug
      }
    }
    lastLoginLog: user_logs(where: {type: {slug: {_eq: "login"}}}, order_by: {created_at: desc}, limit: 1) {
      created_at
    }
    content_logs(order_by: {created_at: desc}) {
      created_at
      user {
        name: last_name
        first_name
      }
    }
  }
}
`

const GC_ADD_USER_ONE = gql`
mutation AddUser(
    $name: String!,
    $first_name: String!,
    $email: String!,
    $role: String!,
    $disabled: Boolean!,
    $cognito_id: String,
    $metas: [user_meta_insert_input!]!,
    $groups: [group_user_insert_input!]!
  ) {
  insert_user_one(object: {
    last_name: $name,
    first_name: $first_name,
    email: $email,
    role: $role,
    disabled: $disabled,
    cognito_id: $cognito_id,
    metas: { data: $metas },
    groups: { data: $groups }
  }) {
    id
  }
}
`

const GC_UPDATE_USER_BY_ID = gql`
mutation UpdateUser(
		$id: uuid!,
		$name: String!,
    $first_name: String!,
    $role: String!,
    $disabled: Boolean!,
    $cognito_id: String,
    $metas: [user_meta_insert_input!]!,
    $groups: [group_user_insert_input!]!
	) {
    update_user_by_pk(pk_columns: { id: $id },
    _set: {
      last_name: $name,
      first_name: $first_name,
      role: $role,
      disabled: $disabled,
      cognito_id: $cognito_id
    }) {
      id
    }

    delete_user_meta(where: { user_id: { _eq: $id } }) {
      affected_rows
    }

    insert_user_meta(objects: $metas) {
      affected_rows
    }

    delete_group_user(where: { user_id: { _eq: $id } }) {
      affected_rows
    }

    insert_group_user(objects: $groups) {
      affected_rows
    }
  }
`

const GC_UPDATE_USER_COGNITO_ID = gql`
mutation UpdateUserCognitoId(
    $id: uuid!,
    $cognito_id: String
  ) {
    update_user_by_pk(pk_columns: { id: $id },
    _set: {
      cognito_id: $cognito_id
    }) {
      id
    }
  }
`

const GC_DELETE_USER_BY_ID = gql`
mutation DeleteUser($id: uuid!) {
  delete_user_by_pk(id: $id) {
    id
  }
}
`

const GC_ADD_MULTIPLE_USER = gql`
mutation AddMultipleUser($users: [user_insert_input!]!) {
  insert_user(objects: $users) {
    affected_rows
  }
}
`

export {
  GC_GET_USER_LIST,
  GC_GET_USER_BY_ID,
  GC_ADD_USER_ONE,
  GC_UPDATE_USER_BY_ID,
  GC_UPDATE_USER_COGNITO_ID,
  GC_DELETE_USER_BY_ID,
  GC_ADD_MULTIPLE_USER
}